<template>
    <div class="gen-human-page page-p-t">
        <div class="genHuman">
            <div class="content">
                <p class="title">一站式工具 GenHuman</p>
                <p class="detail">爱希游打造的虚拟人一站式工具GenHuman依托独创的“虚拟数字人引擎”，可完美刻画人的音容笑貌，并有多种高精度人物模板免费提供，自由捏脸，
为各行各业提供虚拟形象生成、形象驱动、内容创作、互动玩法等生动自然的虚拟交互服务，让虚拟人拥有无限可能，帮助企业客户打造
更面向未来的、更具差异化、更具有竞争力的虚拟人应用产品，赋能企业布局元宇宙生态。</p>
            </div>
            <img width="100%" src="@/assets/img/home/genHuman.png"/>
         </div>
         <div class="tool-box">
            <p class="tool-title">
                <P v-for="(t, index) in toolList" :key="index">
                    <img class="wuxian" v-if="index == toolList.length - 1" src="@/assets/img/genHuman/wuxian.png"/>
                    <span>{{ t.label }}</span>
                    <img class="arrow-img" v-if="index !== toolList.length - 1" src="@/assets/img/genHuman/arrow-right.png"/>
                </P>
            </p>
            <p class="brief">通过三大类虚拟人产品（标准化虚拟人、定制虚拟人、非标准化虚拟人）满足用户对虚拟人创作方面所有需求，GenHuman支持调用上述所有类型虚拟人，通过GenHuman实现虚拟人各种功能使用和场景应用。</p>
            <img src="@/assets/img/genHuman/reward.png"/>
         </div>
         <div class="earth-box">
            <img src="@/assets/img/genHuman/earth.png"/>
         </div>

         <div class="architecture-box">
            <img class="title" src="@/assets/img/genHuman/architecture_title.png"/>
            <img class="img-c" src="@/assets/img/genHuman/architecture.png"/>
         </div> 
         <div class="advantage-box">
            <img class="title" src="@/assets/img/genHuman/advantage_title.png"/>
            <div class="content-box">
                <div class="fixed-label right-t">
                    <img src="@/assets/img/genHuman/guide.png"/>
                    <div>
                        <p class="title">一站解决</p>
                        <p class="detail">真正的一站式工具，傻瓜式操作快速实现90%以上的虚拟人使用场景</p>
                        <p class="line"></p>
                        <p class="detail">丰富形象、简单配置、一键应用、快速上线，绝大部分场景不需定制开发
即便高端定制需求也高度集成于工具，分钟级配置速度，真正的即得即用</p>
                    </div>
                </div>
                <div class="fixed-label right-b">
                    <img src="@/assets/img/genHuman/guide.png"/>
                    <div>
                        <p class="title">成本优势</p>
                        <p class="detail">每个人都可以免费拥有高品质虚拟人</p>
                        <p class="line"></p>
                        <p class="detail">
                            (1)高精度标准虚拟人制作系统，丰富的虚拟人模板，免费获得自己的高品质虚拟人; 
                        </p>
                        <p class="detail">
                            (2)作为新兴行业，市场及其不透明，针对非标准化虚拟人平台提供交易市场提高信息透明度从而进一步降低虚拟人获取成本; 
                        </p>
                        <p class="detail">
                            (3)针对高端定制的高保真和超写实虚拟人，相同精度下成本远低于市场平均水平；
                        </p>
                    </div>
                </div>
                <div class="fixed-label left-b">
                    <div>
                        <p class="title">颜值主义</p>
                        <p class="detail">一群年轻人帮助年轻人做年轻人的生意，颜值即正义!</p>
                        <p class="line line-left"></p>
                        <p class="detail">主要来自游戏、动画行业喜欢二次元文化的年轻团队，立足从客户的客户需求出发，专注于制作年轻群体喜爱的高精度高颜值虚拟人以及相应内容和玩法
                        </p>
                    </div>
                    <img src="@/assets/img/genHuman/guide.png"/>
                </div>
            </div>
         </div>
         <ContactInformation></ContactInformation>
    </div>
</template>
<script>
import ContactInformation from '@/components/footer.vue';

export default {
    name: 'genHuman',
    components: {
        ContactInformation
    },
    data() {
        return {
            toolList: [
                {
                    label: '1个工具'
                },
                {
                    label: '3类产品'
                },
                {
                    label: 'X种应用'
                },
                {
                    label: '可能'
                }
            ]
            
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixin.scss";

    .gen-human-page {
        
        .genHuman {
            overflow: hidden;
            position: relative;
            width: 100%;

            .content {
                position: absolute;
                top: 1.58rem;
                text-align: center;
                z-index: 1;
                .title {
                    @include font-2;
                }
                
                .detail {
                    margin-top: 0.24rem;
                    margin-left: 3.52rem;
                    margin-right: 3.52rem;
                    line-height: 32px;
                    @include de-0-16;
                }
            } 
        }
        .tool-box {
            margin-top: 1.2rem;
            margin-left: $whole-lr;
            margin-right: $whole-lr;

            .tool-title {
                @include font-2;
               
                p {
                    display: inline-block;
                    margin-right: 0.48rem;

                    img.arrow-img {
                        width: 0.64rem;
                        margin-left: 0.48rem;
                    }
                    img.wuxian {
                        width: 0.64rem;
                        margin-right: 0.05rem;
												// filter: sepia(0%);
												// transform: translateY(-0.64rem);
												// filter: drop-shadow(#EFE2DA0 0 0.64rem);
												// filter: drop-shadow(400px 0px #3296FA);
												// filter: grayscale(100%) brightness(150%) saturate(150%);
                        // margin-left: 0.48rem;
                    }
                }
            }

            .brief {
                margin-top: 0.24rem;
                @include de-0-16;
            }

            img {
                margin-top: 1rem;
                width: 100%;
            }
        }

        .earth-box {
            margin-top: 1.97rem;
            margin-left: 2.39rem;
            margin-right: 2.39rem;
            img {
                width: 100%;
            }
        }
        .architecture-box {
            margin-left: $whole-lr;
            margin-right: $whole-lr;
            margin-top: 1.8rem;
            img.img-c {
                width: 100%;
                margin-top: 1rem;
            }
            img.title {
                @include image-title;
            }
        }
        .advantage-box {
            margin-top: 1.8rem;
            position: relative;
            height: 13rem;
            background: black;

            img.title {
                margin-left: $whole-lr;
                @include image-title;
                // margin-bottom: 10rem;
            }
            
            .content-box:hover div.fixed-label{
                    opacity: 1;
                    transform: translateY(0);
            }
            .content-box {
                position: relative;
                background: url('../../assets/img/genHuman/advantage_bg.png') no-repeat;
                background-size: 100% 100%;
                height: 10.8rem;
                width: 100%;

                div.fixed-label {
                    opacity: 0;
                    position: absolute;
                    display: flex;
                    width: 1.02rem;
                    transform: translateY(100);
                    transition: all 0.5s ease-out;
                    
                    img {
                        width: 3.2rem;
                        height: 1.5rem;
                    }
                   
                    div {
                        .title {
                            font-weight: 600;
                            font-size: 0.2rem;
                            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                            font-weight: bold;
                            color: #F5F5FF;
                            line-height: 26px;
                            letter-spacing: 1px;
                        }

                        .line {
                            width: 4.43rem;
                            padding: 0;
                            margin: 0.13rem 0;
                            height: 0.01rem;
                            border: 0.01rem solid;
                            border-image: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%) 1 1; 
                        }
                        .line-left {
                            border-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 100%) 1 1 1 1; 
                        }

                        .detail {
                            @include de-0-16;
                            margin-top: 0.06rem;
                            font-size: 0.14rem;
                        }

                    } 
                }
                div.right-t {
                    top: 0.2rem;
                    left: calc(100% / 2);
                    img {
                        margin-right: 0.16rem;
                    }
                }
                div.right-b {
                    top: 4rem;
                    left: calc(100% / 2 + 2rem);

                    img {
                        width: 2.3rem;
                        margin-right: 0.16rem;
                    }
                }
                div.left-b {
                    top: 3.9rem;
                    left: calc(100% / 2 - 9.4rem);
                    text-align: right;

                    img {
                        margin-left: 0.16rem;
                        width: 2.8rem;
                        transform: rotateX(360deg) rotateY(180deg);
                    }
                }

            }
        }
        
    }
</style>